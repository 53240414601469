<template>
	<div>
		<el-form :model="param" :rules="rules" ref="param" label-position="left" label-width="100px" class="demo-ruleForm">
			<el-form-item label="旧密码" prop="t_old_password">
				<el-input v-model="param.t_old_password"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="t_new_password">
				<el-input type="password" show-password v-model="param.t_new_password"></el-input>
			</el-form-item>
			<el-form-item label="确认新密码" prop="t_new_rePassword">
				<el-input type="password" show-password v-model="param.t_new_rePassword"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" :loading="loadingShow" @click="submitForm('param')">确定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default{
		data(){
			var checkNewPsw= (rule, value, callback) => {
				if(this.param.t_new_rePassword && this.param.t_new_rePassword !== this.param.t_new_password){
					return callback(new Error('两次输入密码不一致!'));
				}else{
					return callback();
				}
			};
			var checkReNewPsw= (rule, value, callback) => {
				if(this.param.t_new_rePassword !== this.param.t_new_password){
					return callback(new Error('两次输入密码不一致!'));
				}else{
					return callback();
				}
			};
			return{
				param: {
					t_old_password:null,
					t_new_password:null,
					t_new_rePassword:null,
				},
				rules: {
					t_old_password: [{ required: true, message: '请输入旧密码', trigger: 'blur'}],
					t_new_password: [
						{ required: true, message: '新密码不能为空', trigger: 'blur'},
						{validator: checkNewPsw, trigger: 'blur'},
					],
					t_new_rePassword: [
						{ required: true, message: '确认新密码不能为空', trigger: 'blur'},
						{validator: checkReNewPsw, trigger: 'blur'},
					]
				},
				loadingShow:false
			}
		},
		mounted() {
			
		},
		methods:{
			
			submitForm(formName){
				let that = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						that.save()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async save() {
				console.log("save")
				let that = this
				let param = {
					url: "updCpsUserPassword",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					if (res.status == 0) {
						localStorage.clear()
						that.$router.push('/login');
						that.$message({
							message: '修改成功',
							type: 'success'
						});
					}
				})
				this.loadingShow = false
			},
		}
	}
</script>

<style scoped="">
	.el-form{
		width: 500px !important;
	}
</style>
